/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Michelské pekárny Premium s.r.o. - Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="js-anim  --anim3 --center pt--80" style={{"backgroundColor":"rgba(207,102,102,1)","paddingBottom":10}} name={"uvod"} anim={"3"}>
          
          <ColumnWrap className="column__flex js-anim  --anim3 --center el--1" columns={"1"} anim={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--62" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"kontaktni-formular"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box fs--20" content={"<span style=\"color: var(--color-custom-1);\">Údaje o společnosti&nbsp;<br><br><span style=\"font-weight: bold;\">Michelské pekárny a. s.\n<br>Michelské pekárny Premium s. r. o.</span></span>"}>
              </Title>

              <Text className="text-box text-box--justify fs--16" content={"<span style=\"font-weight: bold; color: var(--color-blend--15);\">Michelské pekárny a. s.\n</span><br>sídlo společnosti a kontaktní adresa:\nU krčského nádraží 229/17, Krč, 140 00 Praha 4,&nbsp; zapsaná v obchodním rejstříku Městského soudu v Praze, oddíl B, vložka 648\n<br>IČ: 161 92 583\nDIČ: CZ16192583\n<br>Výše základního kapitálu 3.445.498,- Kč\n<br><br><span style=\"font-weight: bold; color: var(--color-blend--15);\">Michelské pekárny Premium s. r. o.</span>\n<br>sídlo společnosti a kontaktní adresa:\nU krčského nádraží 229/17, Krč, 140 00 Praha 4,&nbsp; zapsaná v obchodním rejstříku Městského soudu v Praze, oddíl C, vložka 198709\n<br>IČ: 242 63 362\nDIČ: CZ24263362<br>&nbsp;Výše základního kapitálu 200.000,- Kč"}>
              </Text>

              <Text className="text-box" content={"marketing a obchodní oddělení: +420 602 204 631\n<br>expedice: +420 608 665 587<br>ekonomické oddělení\t+420 606 623 254"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"<br>Provoz objednávkového centra"}>
              </Subtitle>

              <Text className="text-box text-box--justify" content={"Pondělí - Pátek (pracovní dny): 8,00 - 14,00 hod.\n<br>Tel.: +420 602 204 631\n<br>Fax: +420 241 485 026\n<br>E-mail: os.1@pekarny-michle.cz\n<br><br>Objednávky musejí být nahlášeny minimálně 2 pracovní dny před datem dodání. Objednávkám musí předcházet podpis Rámcové kupní smlouvy.<br>Provoz expedice:\n<br>Areál Janáčkova 792/11, Ústí nad Labem, provoz: Pondělí - Pátek 7,00 - 14,00 hod., tel. 704 774 364\n<br>Areál TECHO, U továren 770, Praha 10, provoz: Úterý 8,00 - 10,00 hod. tel. 608 665 587\n<br><br>Prodávající tímto informuje kupujícího, že subjektem mimosoudního řešení spotřebitelských sporů je Česká obchodní inspekce. Internetová stránka tohoto subjektu je http://www.coi.cz/cz/spotrebitel/prava-spotrebitelu/mimosoudni-reseni-spotrebitelskychsporu-adr"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <ContactForm className="--shape2 --style3" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit","align":"right"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" style={{"maxWidth":1420}} anim={"2"} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--10" style={{"maxWidth":366}} content={"Údaje o společnosti <br>Michelské pekárny a.s.\n<br>Michelské pekárny Premium s.r.o.\n<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--11" style={{"maxWidth":366}} content={"<span style=\"font-weight: bold;\">Michelské pekárny Premium s.r.o.\n</span><br>sídlo a kontaktní adresa společnosti:\n<br>U krčského nádraží 229/17, Krč, 140 00 Praha 4\n<br>zapsaná v obchodním rejstříku Městského soudu v Praze,\n<br>oddíl C, vložka 198709\n<br>IČ: 242 63 362 DIČ: CZ24263362\n<br>Výše základního kapitálu 200 000,- Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left fs--11" style={{"maxWidth":473}} content={"<span style=\"font-weight: bold;\">Michelské pekárny a.s.\n</span><br>sídlo a kontaktní adresa společnosti: <br>U krčského nádraží 229/17, Krč, 140 00 Praha 4<br>zapsaná v obchodním rejstříku Městského soudu v Praze, <br>oddíl B, vložka 648\n<br>IČ: 161 92 583 DIČ: CZ16192583\n<br>Výše základního kapitálu 3 445 498,- Kč"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}